import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import { Navigation } from './components/navigation'

import { Header } from './components/header'
import { Features } from './components/features'
import { About } from './components/about'
import { Services } from './components/services'
import { Gallery } from './components/gallery'
import { Main } from './components/main'
import { Contact } from './components/contact'
import { Featured2 } from './components/featured2'
import { Head } from './components/head'
import { Test } from './components/test'
import { Flash } from './components/flash'
import { Contact2 } from './components/contact2'
import { Footer } from './components/footer'
import { Mohawk } from './components/mohawk'
import { Mohawk2 } from './components/mohawk2'
import { Mohawk3 } from './components/mohawk3'
import { Mohawk4 } from './components/mohawk4'
import { Member } from './components/member'

import Modal from "react-bootstrap/Modal";
import JsonData from './data/data.json'

import './App.css';



const App = () => {
	const [landingPageData, setLandingPageData] = useState({})
	useEffect(() => {
		setLandingPageData(JsonData)
	}, [])

	return (
		<div>
			<Navigation />
			<Route exact path="/" component={Header}></Route>
			
			<Flash />
				
			<About data={landingPageData.About} />
			<Features data={landingPageData.Features} />
			<Featured2 data={landingPageData.Featured2} />
			<Mohawk />
			<Mohawk2 />
			<Mohawk3 />
			<Mohawk4 />
			<Services data={landingPageData.Services}  />
			
			
			<Gallery data={landingPageData.Gallery} data2={landingPageData.Carpet} data3={landingPageData.Floor} data4={landingPageData.Pattern} data5={landingPageData.Andover} data6={landingPageData.Mullican} data7={landingPageData.Prescott} data8={landingPageData.Kane} data9={landingPageData.Video} data10={landingPageData.Floorte} data11={landingPageData.RigidCore} isOpen={false} data12={landingPageData.revwood} data13={landingPageData.revwoodplus} data14={landingPageData.revwoodpremier} data15={landingPageData.pergo} data16={landingPageData.solidtech} data17={landingPageData.solidtechs} data18={landingPageData.solidtechp} />
			
			
			
			<Contact data={landingPageData.Contact} data2={landingPageData.Contact2}/>	
			<Contact2 />
			<Member />
			<Footer />
			
		</div>
	);
}

export default App;
